import React, {useState} from 'react';
import './App.css';
import D3Graph from './D3Graph';
import BarChart from './BarChart';
import BayesHelper from './BayesHelper';
import { ResponsiveBar } from '@nivo/bar';

const CustomTooltip = ({ value }) => (
  <div
      style={{
          padding: '5px 10px',
          background: '#fff',
          border: '1px solid #ccc',
      }}
  >
      {value}
  </div>
)

const highlightLayer = ({ bars, xScale, yScale }) => {
  const highlightBar = bars.find(bar => bar.data.indexValue === "Countess of Chester Hospital");
  if (highlightBar) {
      return (
          <rect
              x={highlightBar.x}
              y={highlightBar.y}
              width={highlightBar.width}
              height={highlightBar.height}
              fill="rgba(128, 0, 128, 0.5)"
              stroke="blue" 
              strokeWidth={2}
          />
      );
  }

  return null;
};

const App = () => {
  const barKeys1 = ['Expected Deaths', 'Unexplained'];

  const barKeys = ['Expected Deaths', 'Unexplained', 'Allegedly Letby'];

  const [selectedHospital, setSelectedHospital] = useState(null);

  const handleClick = (bar) => {
    setSelectedHospital(bar.data.id)
  }

  const barDataExclLetby = [
    {
      "id": "2013-14 June-June",
      "Expected Deaths": 4,
    },
    {
      "id": "2014-15 June-June",
      "Expected Deaths": 2,
    },
    {
      "id": "2015-16 June-June",
      "Expected Deaths": 3,
      "Unexplained": 7,
    }
  ];

  const barDataInclLetby = [
    {
      "id": "2013-14 June-June",
      "Expected Deaths": 4,
    },
    {
      "id": "2014-15 June-June",
      "Expected Deaths": 3,
    },
    {
      "id": "2015-16 June-June",
      "Expected Deaths": 3,
      "Unexplained": 7,
      "Allegedly Letby": 7
    }
  ];
  const hospitalDataLabel = ['ratio'];



  const hospitalDataTop10 = [
    {"id":"Western Health & Social Care Trust", "ratio": 2.153846153846154},
    {"id":"Buckinghamshire Healthcare", "ratio": 1.9270833333333335},
    {"id":"South Eastern Health & Social Care Trust", "ratio": 1.8058252427184467},
    {"id":"George Eliot Hospital", "ratio": 1.6881720430107527},
    {"id":"Northern Devon Healthcare", "ratio": 1.6585365853658538},
    {"id":"Calderdale & Huddersfield", "ratio": 1.6213592233009708},
    {"id":"Great Western Hospitals", "ratio": 1.5999999999999999},
    {"id":"Dorset County Hospital", "ratio": 1.5544554455445545},
    {"id":"Epsom & St Helier University Hospitals", "ratio": 1.543478260869565},
    {"id":"The Shrewsbury & Telford Hospital", "ratio": 1.5149253731343282},
     ];

  const hospitalData = [
    {"id":"Western Health & Social Care Trust", "ratio": 2.153846153846154},
    {"id":"Buckinghamshire Healthcare", "ratio": 1.9270833333333335},
    {"id":"South Eastern Health & Social Care Trust", "ratio": 1.8058252427184467},
    {"id":"George Eliot Hospital", "ratio": 1.6881720430107527},
    {"id":"Northern Devon Healthcare", "ratio": 1.6585365853658538},
    {"id":"Calderdale & Huddersfield", "ratio": 1.6213592233009708},
    {"id":"Great Western Hospitals", "ratio": 1.5999999999999999},
    {"id":"Dorset County Hospital", "ratio": 1.5544554455445545},
    {"id":"Epsom & St Helier University Hospitals", "ratio": 1.543478260869565},
    {"id":"The Shrewsbury & Telford Hospital", "ratio": 1.5149253731343282},
    {"id":"Poole Hospital", "ratio": 1.4947368421052631},
    {"id":"Countess of Chester Hospital", "ratio": 1.4921875},
    {"id":"Luton & Dunstable Hospital", "ratio": 1.4780219780219779},
    {"id":"York Teaching Hospital", "ratio": 1.477272727272727},
    {"id":"East Sussex Healthcare", "ratio": 1.4727272727272727},
    {"id":"West Hertfordshire Hospitals", "ratio": 1.47},
    {"id":"Plymouth Hospitals", "ratio": 1.4699453551912567},
    {"id":"Southern Health & Social Care Trust", "ratio": 1.4691358024691357},
    {"id":"NHS Highland", "ratio": 1.4545454545454546},
    {"id":"Heart of England", "ratio": 1.4506172839506173},
    {"id":"Royal Free London", "ratio": 1.4482758620689655},
    {"id":"Cambridge University Hospitals", "ratio": 1.4469026548672568},
    {"id":"Royal Berkshire", "ratio": 1.4444444444444444},
    {"id":"Harrogate & District", "ratio": 1.4367816091954022},
    {"id":"County Durham & Darlington", "ratio": 1.434782608695652},
    {"id":"Aneurin Bevan Health Board", "ratio": 1.417142857142857},
    {"id":"Betsi Cadwaladr University Health Board", "ratio": 1.4148936170212767},
    {"id":"Royal Surrey County Hospital", "ratio": 1.375},
    {"id":"Wye Valley", "ratio": 1.368421052631579},
    {"id":"Bolton", "ratio": 1.3375796178343948},
    {"id":"St Helens & Knowsley Teaching Hospitals", "ratio": 1.3333333333333335},
    {"id":"Worcestershire Acute Hospitals", "ratio": 1.3309352517985613},
    {"id":"Burton Hospitals", "ratio": 1.3253968253968254},
    {"id":"The Royal Wolverhampton", "ratio": 1.3238095238095238},
    {"id":"Barking Havering & Redbridge University Hospitals", "ratio": 1.320754716981132},
    {"id":"London North West Healthcare", "ratio": 1.2926829268292683},
  ];

  return (
    <div className="App">
      <main>
        <h1>The showtrial of Lucy Letby</h1>
        <p>In April 2017, a story broke in the UK about a police investigation into a rise of infant mortality at the Countess of Chester Hospital. Nurse Lucy Letby was arrested in 2018, 2019 and then for the final time in 2020. After almost three years, she was finally tried and found guilty in August 2023, and sentenced to life. On May 24, 2024, permission to appeal her sentence <a href="https://www.thedailybeast.com/despite-new-yorker-investigation-lucy-letby-loses-baby-killer-appeal">was rejected by the court</a>.</p>
        <p>A <a href="https://www.newyorker.com/magazine/2024/05/20/lucy-letby-was-found-guilty-of-killing-seven-babies-did-she-do-it">recent New Yorker article</a> has laid bare the shaky foundations of the prosecution's case. This has been a startling read for those of us who followed this case through the UK media. At the time I didn't look closely and assumed there must have been some solid evidence found. 

        After I looked into the Letby case again I now have significant doubts.
        
        This mini-article explores the statistics that started the investigation and were then used to convict her. First, before looking for the murderer, lets establish if the rise in deaths between 2014 and 2015 is actually indicative of serial killer by itself.</p>

        <p>Here are the 10 hospitals which saw the largest increase in neonatal deaths from 2014 to 2015. It's expressed as a ratio of the 2015 mortality rate over 2014. Try find the Countess of Chester hospital where Letby worked. <i>Click or tap on any bar to guess.</i></p>
        <div className="chart-container">
        <div className="chart-wrapper">
        <div className="chart-content">
        <ResponsiveBar
            data={hospitalDataTop10}
            keys={hospitalDataLabel}
            indexBy="id"
            margin={{ top: 10, right: 10, bottom: 10, left: 30 }}
            padding={0.2}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'pastel1' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            minValue={1}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Ratio, 2015 over 2014 deaths',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            tooltip={({ value }) => <CustomTooltip value={value} />} 
            onClick={handleClick}
            enableLabel={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
        />
          </div>
          </div>
          </div>

        {selectedHospital === null && (<p><i><strong>Click or tap on any bar to guess.</strong></i></p>)}


        {selectedHospital !== null && (
          <div>
            <p>
              That was <b>{selectedHospital}</b>. Actually it's a trick question. Countess of Chester hospital <strong>isn't in the top 10</strong> hospitals with the largest increase in deaths from 2014 to 2015. This doesn't mean there's not a serial killer. It just means other hospitals also saw jumps, presumably independently of a serial killer.
            </p>
            <p>
              It's number 12.
            </p>
            <div className="chart-container">
            <div className="chart-wrapper">
            <div className="chart-content">

            <ResponsiveBar
                data={hospitalData}
                keys={hospitalDataLabel}
                indexBy="id"
                margin={{ top: 10, right: 10, bottom: 10, left: 30 }}
                padding={0.2}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'pastel1' }}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                minValue={1}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Ratio, 2015 over 2014 deaths',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                fill={[
                  {
                    match: d => d.ratio === 1.4921875,
                    id: 'highlighted',
                      color: 'rgba(255, 0, 0, 0.6)'
                  }
                ]}

                enableLabel={false}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                layers={['grid', 'axes', 'bars', 'markers', 'legends', highlightLayer]}
            />
          </div>
          </div>
          </div>

          <p>
            <i>All data is taken directly from <a href='https://www.npeu.ox.ac.uk/mbrrace-uk/reports/perinatal-mortality-surveillance'>MBRRACE</a>, see 2014 and 2015 reports. (I pulled the data out of the PDF and into csv <a href='https://triedbystats-public-site-resources.s3.us-west-2.amazonaws.com/2014.csv'>2014</a> and <a href='https://triedbystats-public-site-resources.s3.us-west-2.amazonaws.com/2015.csv'>2015</a>). Note: These rates are the stabilized & adjusted rates from MBRRACE.</i>
          </p>

          </div>
        )}

        <p>
          Do you think there are serial killers at work in the other hospitals? Or do you think those can be explained by other non-murderous factors? Why can't countess?</p>
          
        <p>There are two key points I hope to get across with this page. Firstly, the "evidence" against her exists independently of her being a murderer, and thus not being evidence of her being a murderer. Secondly, the fact that the evidence is statistically insignificant was largely withheld at her trial. The jury was invited to speculate after being told the highly probable was impossible, and the vanishingly unlikely was certain.</p>
        <p>Despite how it is framed, this case wasn't started as an investigation into a "cluster of neonatal deaths" or "unexplained rise in mortality rate". It was always, and only, an investigation into Lucy Letby. Just as that investigation started by isolating her as the "single common factor" in a table of shifts, we start by looking at the chances of getting the same chart through random noise.
        </p>

        <section id="d3-section">
          <h2>The single common factor</h2>
          <blockquote>Lucy Letby was the consistent through this. She was the thread running through them all. Therefore, in evidential terms the biggest source of evidence.<footer><cite><a href='https://youtu.be/RdHxvO6bLqg?t=1028'>Detective Superintendent Paul Hughes, Senior Investigating Officer</a></cite></footer></blockquote>
          <blockquote>If you look at the table overall the picture is, we say, self-evidently obvious. It's a process of elimination.<footer><cite><a href='https://www.bbc.com/news/uk-england-merseyside-63201201'>Prosecutor Nick Johnson KC, during trial</a></cite></footer></blockquote>
          <p>This is the shift data the prosecution showed for Letby. It shows she is the only nurse on for ALL the suspicious events they say happened. It's false even by their own criteria because in some of the cases she actually wasn't on shift when the event allegedly occurred, but was on the previous shift. It also excludes events they identified as suspicious that occurred when she wasn't on shift.</p>
          <img src="https://triedbystats-public-site-resources.s3.us-west-2.amazonaws.com/shift_data.jpeg" alt="https://x.com/LucyLetbyTrial/status/1653069266843848709/photo/1"></img>          
          <D3Graph />
        </section>

        <section id="new-section">
          <h2>The Prosecutor's Fallacy</h2>

          <p>The point of the example above is to show how incriminating shift patterns are trivially recreated through random noise, making it meaningless on its own. 
            What the prosecution <i>should</i> have shown is that the pattern they uncovered was extremely unlikely to occur under normal circumstances.
             Instead, in this case, they used the above chart to suggest there's a series of coincidences that must be answered, with the most logical answer being that Letby is a serial killer.</p>
          <p>To see how Letby becomes the "common factor", take the two cases involving alleged insulin poisonings. I want to be clear: these "poisonings" were not suspected at the time. These were uncovered in 2018 by the same doctor who accused Lucy in the summer of 2015, as he was digging through notes looking for more evidence against her. The only evidence is the blood samples and the fact that Lucy was on shift that day... Or the day before. During cross-examination, the prosecution simultaneously argued it made sense in Child L's case for the poisoning to occur while the bag was next to the baby, and for Child F, argued that since the chain of custody was so weak, anyone could have tampered with the bag at any point before arrival.</p>
          <ul>
            <li>Child L:<a href='https://news.sky.com/story/lucy-letby-trial-latest-nurse-baby-murders-prosecution-sky-news-blog-12868375?postid=6019774#liveblog-body'>"It follows that somebody has put [insulin] into the bag whilst it was hanging [next to the child]," Mr Johnson says.</a></li>
            <li>Child F:<a href='https://news.sky.com/story/lucy-letby-trial-latest-nurse-baby-murders-prosecution-sky-news-blog-12868375?postid=6010865#liveblog-body'>"A nutrition bag must have been tampered ...The bags are kept locked down on the unit, but "they are not secure from someone who has a key and wants to tamper with them," prosecution barrister Nick Johnson says"</a></li>
          </ul>
          <p>In other words</p>
          <ul>
            <li>Child L. Letby must have poisoned the baby since she was on duty that day.</li>
            <li>Child F. Letby must have poisoned the IV bags used because she wasn't on duty that day.</li>
          </ul>
          <p>This is the sort of circular logic prevalent throughout this case. We now know that the lab which performed the test states that the test <i>should not</i> be used to investigate exogenous insulin. The lab told the hospital to refer the sample for further analysis at a specialist lab, which they did not do.</p>
          <blockquote><i>Please note that the insulin assay performed at RLUH is not suitable
            for the investigation of factitious hypoglycaemia. If exogenous
            insulin administration is suspected as the cause of hypoglycaemia,
            please inform the laboratory so that the sample can be referred
            externally for analysis.</i>
            <footer><cite><a href='http://pathlabs.rlbuht.nhs.uk/insulin.pdf'>Warning carried by the lab performing the test</a></cite></footer></blockquote>

          <p>The probability of a baby dying is low. Do you know what's even less likely? A baby being murdered. In every part of this case, they insist that the chance of the death being natural is either zero or close to zero; therefore, it must be murder. By definition, if there's a murder, there must be a murderer.</p>
          <blockquote>This (mis-)interpretation is a serious error of logic known as the Prosecutor's Fallacy. The
            jury needs to weigh up two competing explanations for the babies' deaths: SIDS or murder. Two deaths
            by SIDS or two murders are each quite unlikely, but one has apparently happened in this case. <b>What
            matters is the relative likelihood of the deaths under each explanation, not just how unlikely they are
            under one explanation</b>
            <footer><cite><a href='https://rss.org.uk/RSS/media/File-library/Membership/Sections/2020/Sally-Clark-RSS-letter-2002.pdf'>Royal Statistical Society's 2001 letter leading to the exoneration of Sally Clark</a></cite></footer>
          </blockquote>
          <p>In the Clark case the chances being compared were murder or death of an infant outside the hospital. Both events rare but clearly natural death is more common. In the Letby case it is death inside an ICU, maybe uncommon but hardly unheard of, and murder in an ICU, nearly unprecedented. 
            
            In both cases the deaths were not unexplained at the time. In both cases autopsies and a coroner had identified the deaths as being from natural causes. In both cases previously identified natural causes were superseded by belief in a statistical pattern and the objective assessments of pathologists gave way to the speculations of paediatricians.</p>

          <p>One step of a statistical analysis like this would be to factor in the prior probabilities of an event and of Lucy working into the analysis. I.E do these events happen independently of a murderer, and if they do was she likely to be around when it happens. Consider the reasons we have to think Lucy may have had a higher chance of working:</p>
          <blockquote>At the time we were talking about we had massive staffing issues where people were coming in and doing extra shifts.
            It was mainly Lucy that did a lot as <b>she was one of only three band five nurses that had done the neonatal course</b> at that time.
            ... Lucy was young, living in halls in the doctors' residential, saving to buy a house, she was single, able to swap, willing and wanting to do extras
            <footer><cite><a href='https://www.bbc.com/news/uk-england-merseyside-64419757'>Evidence from trial</a></cite></footer></blockquote>

          <blockquote>"she messages a manager of the neonatal unit, <b>offering to work more shifts</b>, saying she needs to throw herself back in"
            <footer><cite><a href='https://www.bbc.com/news/uk-66120198'>Evidence from trial</a></cite></footer></blockquote>

          <blockquote>"A text message...is shown to the court asking Lucy Letby to work that night. Letby tells the court she was "frequently" asked to come in and cover neonatal unit shifts at short notice, <b>saying she was very "flexible"</b>
            <footer><cite><a href='https://www.bbc.com/news/uk-66120198'>Evidence from trial</a></cite></footer></blockquote>

          <blockquote>
            She worked night shifts on June 8-9, 9-10, 13-14 and 14-15.
            Letby had messaged Yvonne Griffiths if there were any spare shifts going on June 11.
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23502385.recap-lucy-letby-trial-friday-may-5---defence-continues/'>Evidence from trial</a> (shifts are 12 hours, so she worked 48 hours that 6.5 day period but had volunteered for 60)</cite></footer>
          </blockquote>

          <p>For the other parts of the equation, it becomes much more subjective, not least because of the incredible selection bias of what counts as an event. In most of the deaths, an autopsy was performed and a diagnosis was given for a natural cause of death. Natural causes identified by pathologists at the time are being overturned by the former pediatrician who serves as the prosecution's key expert witness. Mostly he does this through retrospective analysis of doctors' notes. A death is suspicious because Letby was on shift and the prosecution's medical expert says it is suspicious. It does not seem credible to me that the probability of each death being the original natural cause identified is zero.</p>
          <p>Lets look at Child C, a 7 week premature baby weighing 800g (~1.8lb).</p>

          <blockquote>
            Dr Beech noted, from the June 12 x-ray, it was "very gaseous", and the result of CPAP.
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23081233.recap-lucy-letby-trial-thursday-october-27/'>Evidence from trial</a></cite></footer>
          </blockquote>
          <p>Letby wasn't on shift on the 12th. Still, the prosecution's key medical witness identified this as attempted murder since it had the hallmarks of the other attempted murders / murders he has identified in this case.</p>
          <blockquote>
            <ul>
              <li>Mr Myers says the 2019 report said Dr Evans raised a possibility of deliberate injection of air from June 12 via the naso-gastric tube.</li>
              <li>Dr Evans, reflecting on that report, said: "Can't rule it out".</li>
              <li>Mr Myers refers to a 'massive gastric dilation' was 'most likely' due to an injection of air on June 12.</li>
              <li>Dr Evans: "That was a possibility, yes."</li>
              <li>Mr Myers said that it was Dr Evans's view, a couple of months ago, there was deliberate harm on June 12.</li>
              <li>"That was a possibility, yes it was."</li>
            </ul>
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23092103.recap-lucy-letby-trial-tuesday-november-1/'>Evidence from trial</a> (A reader points out he only changed his mind about his report on the day of the trial, from "most likely" to "a possibility")</cite></footer>
          </blockquote>
          <p>This clearly shows that there is, in fact, a false positive rate to his diagnoses. He knows what the doctor working at the time thought and doesn't care. For some babies, he says it's most likely air injection; therefore, it's attempted murder. In another case, he says it's most likely air injection... but Letby wasn't on shift, so it must have a natural explanation other than attempted murder. The acknowledgment of even one false positive actually torpedoes the chances of the others being correct as well. Here's why.</p>

          <p>Lets assume just one false positive (though there's clear documentation of more). Letby was convicted of 7 murders and 6 attempted murders. That gives him a specificity of 13/14, every 14 events he identifies as one of his murder scenarios 1 event is not a murder and is a false positive. Lets say he has sensitivity of 100% so if you give him a case with a murder in it he will identify it correctly every time. Though, he cleared some cases then doubled back later so even thats not true.</p>

          <p>Prior to Letby the only precedent for baby murder in a hospital is Beverly Allitt. She was convicted of murdering 4 babies in 1991. About ~2000 babies die per year in the UK given the 2023 rate. For simplicity lets just use that. So over 25 years between 1991-2016 we can say about 50,000 babies died. Lets say 20,000 of those happened in the hospital. If you don't agree with these you can change them in the parameters below.</p>
          <p>Specificity of ~93% and sensitivity of 100%. Using the Beverly Allitt data we can approximate a prior probability of murder of 4 / 20,000. So <i>given</i> that we get a murder diagnosis from Dr Evans what are the chances a murder occurred? Well at 93% specificity you may think theres a 93% chance that it's a true murder. <strong>It's not 93% it's 0.285%</strong>. To be clear, <i>with these values</i> if Dr Evans tells you there's a murder it's actually a murder less than 1% of the time. Even if his specificity was 99% the chance only rises to 1.96%. This is because the prior improbability is so minute that it drowns out the other factors.</p>

          <p>This is obviously completely dependent on how the priors selected. My point is just to illustrate the relationship between them. Use the tools below to change any of the priors. Using "+" to mean positive result, so a real murder given a diagnosis of murder from Evans would be P(Murder | +):</p>
          <BayesHelper initialFp={0.07}/>

          <p>The chance of winning the lottery is tiny but someone wins it most times. It doesn't mean that person cheated. The point isn't to say that the chances are low so Letby isn't a killer. The point is the chances are low so the other evidence has to be solid to increase the probability of her being a murderer independently to outweigh the false positive rate. Poor interpretation of a statistical pattern lead them to believe there was a murderer so they started looking for murders.</p>
          <p>There's a tendency to just dismiss this as being a statistical technicality. To some extent, that is true. If someone comes into the hospital with pneumonia and then dies of a bullet wound, that's suspicious. We don't really need to talk about the prior probability of deaths in hospitals. The mathematics would still work out if you did. Bayes rule is just a framework to update previous probabilities as each piece of evidence is evaluated. Presumably the false positive for a bullet wound is near zero.</p>
            
          <p>This is what's being asserted in this case: the medical signal of foul play is unmistakable. Multiple doctors saying things like "natural causes ruled out" or that the "only possibility" is foul play. Medical experts are now critiquing that, but even the evidence in this trial refutes it directly. They identified an attack and then said an attack didn't take place. An unambiguous false positive. This is a fundamental statistical problem at the heart of the case. The doctors presented these as black swan events when actually they occurred even in this case independently of Letby.</p>

          <blockquote>'Initially, I looked at 32 cases and there are seven of those [which were not part of the trial] that need more scrutiny.` ... he was asked to review the notes of another 48 babies - not included in the trial - and found concerns with as many as 18.<footer><cite><a href='https://www.dailymail.co.uk/news/article-12529309/Lucy-Letby-maybe-murdered-THREE-babies.html'>Dr Evans interview with the Daily Mail</a></cite></footer></blockquote>
          <p>Letby is accused of 25 incidents if each of those is a "case" the seven extra cases would mean Dr Evans identified harm in every single case he was given by the police. If each case is a patient then it would be 24/32. He was given the cases handpicked by the doctors who accused Letby. Of course she's the common factor in them!</p>

          <blockquote>Evans identified events that may have been left out, too. He told me that, after Letby's first arrest, he was given another batch of medical records to review, and that he had notified the police of twenty-five more cases that he thought the police should investigate. He didn't know if Letby was present for them<footer><cite><a href='https://www.newyorker.com/magazine/2024/05/20/lucy-letby-was-found-guilty-of-killing-seven-babies-did-she-do-it'>Rachel Aviv in conversation with Dr Evans </a></cite></footer></blockquote>

          <p>On the insulin we have false positives too. The doctor who identified the first two later found a third and during the trial it was revealed the hospital has a "usual" response.</p>
          <blockquote>
            Brearey also discovered that, eight months later, a biochemist at the lab had flagged a high level of insulin in the blood sample of another infant.
            <footer><cite><a href='https://www.newyorker.com/magazine/2024/05/20/lucy-letby-was-found-guilty-of-killing-seven-babies-did-she-do-it'>Rachel Aviv in conversation with Dr Brearey</a></cite></footer>
          </blockquote>
          <blockquote>
            <p>The note adds '?Exogenous' - ie query whether it was insulin administered.</p>
            <p>The note added 'Suggest send sample to Guildford for exogenous insulin.'</p>
            <p>The court hears Guildford has a specialist, separate laboratory for such analysis in insulin, although the advice given to send the sample is not <strong>usually</strong> taken up by hospitals.</p>
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23149016.recap-lucy-letby-trial-friday-november-25/'>Evidence from trial</a></cite></footer>
          </blockquote>
          <p>The advice to send samples to the lab is not <i>usually</i> followed. This implies they've had results like this before since they have a usual approach to deal with it.</p>

          <p>Anyway, lets turn back to Dr Evans to hear how he explained his methodology of distinguishing between a false positive and true positive. Remember he falsely identified the air injection on the 12th when Letby wasn't on shift from an X-Ray. This is how he identified the air injection on the 13th when she was on shift:</p>
          <blockquote>
            <ul>
              <li>Mr Myers asks Dr Evans what evidence there is to support that air had been injected into the stomach on June 13. </li>
              <li>Dr Evans: "The baby collapsed and died."</li>
            </ul>
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23092103.recap-lucy-letby-trial-tuesday-november-1/'>Evidence from trial</a></cite></footer>
          </blockquote>

          <p>Circular logic once again. Also his peer reviewer states that the air injection isn't the only possible cause.</p>
          <blockquote>
            [Dr Bohin] said one conclusion for Child C's collapse was CPAP accumulation of air, the other being deliberate injection of air.
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23092103.recap-lucy-letby-trial-tuesday-november-1/'>Evidence from trial</a></cite></footer>
          </blockquote>

          <p>On this same baby lets look at evidence that Letby murdered this baby. Letby is on shift in room 3, the non ICU nursery. Baby C is in room 1, the ICU. At 10.21pm hospital records show she is in room 3. Child C crashed at 11:15pm and Letby was called into the room to respond. Years after the event another nurse has said Letby was in the room shortly after 11:04pm, before the crash. That is the only evidence that "links" her to this. The other nurses didn't see or hear anything suspicious. No one made any accusation at the time. Pathologist recorded natural death from pneumonia at the time.</p>
          <blockquote>
            Dr Bohin said it was "very clear" Child C had pneumonia, but a baby with pneumonia will "often survive"...
            Child C's breathing rate was "very stable", despite "effectively breathing with one lung".
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23092103.recap-lucy-letby-trial-tuesday-november-1/'>Evidence from trial</a></cite></footer>
          </blockquote>

          <blockquote>
            Another note by Dr Ogden at June 13, 9.30am is made for Child C.
            The list of 'problems' is noted, including 'RDS' and 'suspected sepsis'.
            Dr Ogden says the oxygen levels had gone down on CPAP to 26%.
            Child C's breathing was "stable and possibly improved".
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23081233.recap-lucy-letby-trial-thursday-october-27/'>Evidence from trial</a></cite></footer>
          </blockquote>

          <p>Additionally, when considering the probability of an event, just like in the Sally Clark case where one major flaw of the statistical "analysis" was to treat the events as independent when actually the babies are related to each other, in this case, there are three sets of twins and one set of triplets. The chance of both twins suffering from the same medical problem seems to me to be intuitively more likely than two independent babies.</p>
          <h1>What are the chances we're both serial killers?</h1>
          <p>This investigation started because one doctor got suspicious specifically of Letby after noticing she was on shift for 3 baby deaths in one month. These are Babies A, C, and D. The first three deaths. Coincidentally 3 is also the number of expected baby deaths the hospital would have in any year. Letby was charged for 7 deaths over a 13 month period between June 2015 and July 2016.</p>
          <div className="chart-container">
            <div className="chart-wrapper">
            <div className="chart-content">
            <BarChart data={barDataExclLetby} keys={barKeys1} />
          </div>
          </div>
          </div>
          <p>Can you spot the year Letby was allegedly active? I bet you can, 2015 and 2016.</p>
          <p>Could this mean there's a serial killer active?</p>
          <p>Could be, one thing to consider though. The deaths during that period actually are <b>17</b> not <b>10</b>; I <b>excluded</b> the 7 deaths Lucy Letby was charged with.  Given that in one case she was charged with attempted murder with the sole evidence being that she was working the day before, it's safe to assume that if the doctors could have linked her to those 10, they would have found something. The point is, there is reason to believe that something in the environment was causing an abnormally high death rate at the same time. So, we are to believe there was something in the environment <i>and</i> a serial killer? What bad luck.</p>
          <div className="chart-container">
            <div className="chart-wrapper">
            <div className="chart-content">
            <BarChart data={barDataInclLetby} keys={barKeys} />
          </div>
          </div>
          </div>

          <p>Couldn't it just be the first factor and not the second? They've not identified the cause of the rise of deaths, and don't appear particularly interested in doing so, whatever it was so we can't tell. Obviously 17 is more of an outlier from 3 than 10 is. However 10 is still an outlier.</p>
          <p>This section builds on original research by mephitis.co who modeled the deaths <a href='https://mephitis.co/more-remarkable-statistics-in-the-lucy-letby-case/'>with the poisson distribution</a> and pulled the death data from a freedom of information request to the hospital. <a href='https://mephitis.co/lucy-letby-a-further-look-at-the-infant-mortality-statistics/'>As they noted 2 of the deaths don't appear in the FOI data</a> which only reports 15 but excludes 2 she was charged with.</p>

          <p>The 17 number is also corroborated by reporting at the time.</p>

          <blockquote>The police began an investigation into infant deaths at the hospital in May 2017, initially looking at the deaths of 15 babies between June 2015 and June 2016. On Tuesday police said the investigation had been widened to include 17 deaths and 15 non-fatal collapses of babies between March 2015 and July 2016.<footer><cite><a href='https://www.theguardian.com/uk-news/2018/jul/04/cheshire-baby-deaths-nurse-questioned-suspicion-murder-lucy-letby'>The Guardian report on the police inquiry</a></cite></footer></blockquote>


          <h1>Cherry picking</h1>
          <p>It's obvious that the case against Lucy Letby is making up for the lack of any direct evidence by presenting lots of weak evidence and pointing at the shift chart. The shift chart, as we've explored, is no better than random noise without statistical analysis. Even if the medical evidence was accepted as indicating a murder happened in each case, which it isn't, there's still no connection that Letby is the murderer in question. Except, of course, the shift chart and the "series of coincidences".</p>
          <p>None of the evidence actually seems to stand on its own and is really an invitation to fill in the blanks. This includes:</p>
          <ul>
            <li><a href='https://www.bbc.com/news/uk-england-merseyside-64496406'>A condolences card sent to the family of a baby who died</a></li>
            <li><a href='https://www.mirror.co.uk/news/uk-news/chilling-explanation-lucy-letbys-disturbing-31674575'>Searching for the family of a child on Facebook on Christmas Day</a></li>
          </ul>
          <p>What does this tell us? Well, given these are families that she interacted with and watched them lose children in front of her eyes, I would guess that she was thinking about them and their loss. Are there any other explanations for searching the family members on Christmas Day other than thinking about them and what they are going through? I recommend clicking the second link to understand how this mundane evidence is portrayed in the UK media.</p>
          <p>It almost seems like padding to make it appear like they have more evidence and that there are more "coincidences" to explain. Leaving aside the Rorschach test/dog whistle nature of this evidence, it's also cherry-picked.</p>
          <ul>
            <li>She searched 31 times for family members, but this is selected from a total 2,287 searches over the same period.</li>
            <li>It's claimed she kept "trophies" in the form of 21 handover sheets related to babies in this case, actually these where picked out of 257 mundane records that she had retained</li>
          </ul>
          
          <p>She also searched specifically for parents of babies NOT subject to the investigation.</p>

          <blockquote>
          She is asked whether Letby searched on Facebook for the parents of children other than those listed in the charges.
          Ms Hocknell confirms that is the case. "There are a lot of searches for different people."
          <footer><cite><a href='https://www.chesterstandard.co.uk/news/23060130.recap-lucy-letby-trial-wednesday-october-19/'>Evidence from trial</a></cite>(Hocknell is the Police Intelligence Analyst).</footer></blockquote>

          <p>So actually rather than showing a fixation as they strongly implied in the coverage, the context of the evidence is on face value exculpatory.</p>
          <ul>
            <li>Claim: Fixated with parents of babies who died.</li>
            <li>Counter claim: <a href='https://www.bbc.com/news/uk-england-merseyside-65454700'>"she was always on my phone" and would carry out searches "out of general curiosity", adding it was "just normal behaviour for me".</a>.</li>
            <li>Evidence: yeah that's about right.</li>
          </ul>
          <p>The prosecution offer the claim of fixation and say the fixation is because of murder, then they ask her "or do you say it's just a coincidence". They don't bother to explain why these 31 searches and 21 records couldn't be explained by the same benign reasons the other 2,256 searches were made, or the 236 records kept. Without such an explanation to distinguish these from what is clearly her normal pattern of behavior, one must ask the prosecution, if these things mean fixation and murder why did she "coincidentally" keep 236 records and make 2,256 searches for people she <i>isn't</i> accused of murdering?</p>
          <p>Under the innocence hypothesis the explanation is obviously more intuitive. Unless you are of course a certain former pediatrician who takes an even more extreme position.</p>
          <blockquote>
            <p>I thought ‘Oh my God these are trophies...</p>
            <p>The defence tried to pooh-pooh this and say, ‘No no, she is just a hoarder’. But the alternative interpretation of that is that she may have tried to put all of those babies in harm’s way. I wouldn’t be happy to close the case, the file, until at least those 257 cases were looked at</p>
            <footer><cite><a href='https://archive.ph/TWHv9'>Dr Evans, in one of his many media interviews</a></cite></footer></blockquote>
          <p>In other words, we must first eliminate the murder hypothesis before we can consider the innocence hypothesis. This glimpse into the mind of the prosecution's chief medical examiner explains a lot about this case. He is given, on face value, the most plausible explanation, dismisses it without any evidence, and without any knowledge of the contents, jumps to "but the alternative interpretation".</p>
          <p>On the condolences card there's an extra element which is almost always omitted.</p>
          <blockquote>

            <ul>
              <li>Letby: “Yeah, I sent a sympathy card to the parents ‘cos I wasn’t able to attend the funeral.”</li>
              <li>Detective: “OK, is this normal practice, Lucy?”</li>
              <li>Letby: “No. Well, it’s not very often that we would get to know a family as well as we did with (Child I).”</li>
              <li>Detective: “OK, is there a reason why you didn’t go to the funeral?”</li>
              <li>Letby:“I was working. I wasn’t able to change my shift. It was suggested that I could send a card <b>via one of the other nurses who was going.</b>”</li>
            </ul>
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23480468.lucy-letby-took-photo-sympathy-card-remember-kind-words/'>Police interview</a></cite></footer></blockquote>
          <p>The card was in the context of other staff at the hospital going to the funeral. What is the coincidence they want her to answer here? That she coincidentally worked at the same hospital? Did any other nurses send condolences cards?</p>

          <p>I do have to say, there is one piece of evidence which on face value does appear to be actual evidence. The note. While under police investigation, after losing her job and having her life destroyed, Letby wrote a number of notes in her room. One of these notes at the end contains what appears to be a partial confession.</p>
          <img src="https://triedbystats-public-site-resources.s3.us-west-2.amazonaws.com/note.jpg"></img>
          <p>In blue, incriminating statements.</p>
          <ul>
            <li>I DID THIS</li>
            <li>I killed them on purpose because I wasn't good enough to care for them</li>
            <li>I AM EVIL I DID THIS</li>
          </ul>
          <p>However, these are arguably cherry picked because 5 lines above (in red) are denials</p>
          <ul>
            <li>I haven't done anything wrong</li>
            <li>Police investigation</li>
            <li>Slander, discrimination, victimization</li>
          </ul>
          <p>This one is subjective. There's no maths that can tell you the probability of it being a genuine confession (at least not without relying heavily on subjective assumptions). I can say what I believe. I think this is a statement made by someone who has had their life turned upside down by a police investigation. In her texts to fellow nurses there's a recurring motif of her questioning if she did enough. This feels like a continuation of that. But under the other hypothesis? That she's a murderer and in the middle of a rambling note decided to confess? While also denying it in the same confession?</p>
          <blockquote>
            <ul>
              <li>[RE: I killed them on purpose note] <i>Letby: "I [felt as though I] hadn't been good enough and in some way I had failed [in my duties, my competencies]...that was insinuated to me."</i></li>
              <li><i>"I felt at the time if I had done something wrong, I must have been an awful person..."</i></li>
              <li><i>Letby says she feared she may have been "incompetent" and because of that, she had "harmed those babies".</i></li>
              <li><i>She adds she could not understand "why this happened to me".</i></li>
              <li><i>She says, looking back, she was "really struggling" at the time of writing the note.</i></li>
            </ul>
            <footer><cite><a href='https://www.chesterstandard.co.uk/news/23493710.recap-lucy-letby-trial-tuesday-may-2---defence-begins/'>Reporting on Letby's statements during trial</a></cite></footer>
          </blockquote>
          <p>You will have to make up your own mind. Her explanation of why she wrote these notes seems reasonable. I believe her.</p>

          <h1>Summing up</h1>
          <p>I originally titled this mini-article "Britain's Unluckiest Nurse." I liked the title, but as I reviewed my research of the case, I realized there wasn't any bad luck involved. There is no direct evidence that Letby did anything. None. There's not even any circumstantial evidence that is remotely convincing. She really didn't have any bad luck. I believe that misplaced faith in a statistical pattern that doesn't exist led to an investigation by doctors who were absolutely adamant they had found a serial killer. The supposed "too many coincidences" appear to be no more than selection bias from her investigators and totally mundane events.</p>
          <p>In retrospect, many aspects of this case make absolutely no sense. How is it that she was able to murder Baby A inside an incubator in a room with two doctors and two other nurses just feet away, and just 20 minutes after the handover? Similarly, how could she kill Baby C within the 11 minutes between 11:04 pm and the alarm going off at 11:15 pm, but then fail to murder others despite multiple alleged attempts over weeks? As time goes on, the cases become even weaker and more contrived.</p>
          <p>The murder charges for Babies A, C, D, E, I, O, and P, while also dubiously evidenced, at least fit the pattern of all having the same alleged cause of death (though not all the same diagnosed cause at the time). After that, the accusations become increasingly scattershot: dislodging a breathing tube, insulin poisoning, overfeeding. It appears she became a worse serial killer as time went on, moving from an incredibly effective method that killed in minutes and went undetected by pathologists in the autopsy (and everyone else), to ineffective forms like dislodging a breathing tube.</p>
          <p>To me, the more obvious explanation is that the prosecution is trying to fit the theory to the facts.</p>

          <h3>Human cost</h3>

          <p>Lucy Letby was 26 when she was removed from the job she loved and had been training for years to do. Arrested at 28. By 30 she was denied bail and held in pre-trial detention until her trial three years later. She will spend the rest of her life in prison. It took over three years for Sally Clark to be freed. If the same holds here, Letby will be 37. She will have been in prison or pre-trial detention for 7 years. Her parents who are in their 60/70s moved to live near the prison housing their daughter.</p>
          <blockquote>
            Throughout my review, I was horrified by the shoddy fashion in which these cases were evaluated. It was clear that sound medical principles were abandoned in favour of over-simplification, over-interpretation, exclusion of relevant data and, in several instances, the imagining of non-existent findings.
            <footer><cite><a href='https://en.wikipedia.org/wiki/Sally_Clark#Successful_second_appeal'>Review of Sally Clark case</a>, sound familiar?</cite></footer>
          </blockquote>

          <p>The hope of this page is to succinctly communicate some of the problems raised by this case. I believe now an innocent nurse is on track to spend the rest of her life in prison. My hope is that more people look again at this case and look objectively at the statistics and the medical data used. I strongly encourage you to read and share as wildly as you can the <a href="https://www.newyorker.com/magazine/2024/05/20/lucy-letby-was-found-guilty-of-killing-seven-babies-did-she-do-it"> New Yorker article</a> (<a href='https://web.archive.org/web/20240525142120/https://www.newyorker.com/magazine/2024/05/20/lucy-letby-was-found-guilty-of-killing-seven-babies-did-she-do-it'>UK link</a> as it is censored in the UK).</p>

          <p>Unlike me, many people have raised problems with this case since day one. A non-exhaustive list of people who have been raising the alarm.</p>
          <ul>
            <li><a href='https://mephitis.co'>Mephitis.co</a>, By Peter Elston, who was one of the first to raise the statistical problems with this case.</li>
            <li><a href='https://www.scienceontrial.com'>Science On Trial</a>, see their dedicated Letby site <a href='https://rexvlucyletby2023.com'>https://rexvlucyletby2023.com</a></li>
            <li><a href='https://gill1109.com'>Dr Richard Gill</a> (previously exposed statistical problems in another wrongly convicted nurse, Lucia de Berk)</li>
          </ul>

          <p>Feel free to forward all questions, comments, corrections and court orders to triedbystats@gmail.com.</p>

          <p>Can also be reached on <a href='https://x.com/triedbystats'>Twitter</a></p>
        </section>

      </main>
    </div>
  );
}

export default App; 