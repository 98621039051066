import React, { useState } from 'react';
import { ReactComponent as AHandover } from './A_handover.svg'; 
import { ReactComponent as AEvent } from './A_event.svg'; 

const LetterPanel = () => {
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [selectedOption, setSelectedOption] = useState(0);

  const letters = ['A'];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };


  const handleClick = (letter) => {
    setSelectedLetter(letter);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex space-x-4 mb-4">
        {letters.map((letter) => (
          <button
            key={letter}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
            onClick={() => handleClick(letter)}
          >
            {letter}
          </button>
        ))}
      </div>
      {selectedLetter && (
        <div className="mt-4 p-4 border rounded bg-gray-100 flex mx-auto">
          <div className="w-2/10 p-2 border-r flex flex-col space-y-2">
              <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(0)}
              >June 7th</p>
              <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(1)}
              >11:50pm</p>
              <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(2)}
              >6:47am</p>
              <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(3)}
              >8:00am</p>
              <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(4)}
              >2:00pm</p>

            <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(6)}
              >7:00pm</p>

            <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(7)}
              >7:30pm</p>

            <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(8)}
              >8:00pm</p>

            <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(9)}
              >8:05pm</p>

            <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(10)}
              >8:18pm</p>
            <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(11)}
              >8:20pm</p>
            <p className="text-blue-500 hover:text-blue-700 text-left"
                onClick={() => handleOptionClick(12)}
              >8:26pm</p>

          </div>
          <div className="w-8/10 p-4">
            <div className="mt-42">
              {selectedOption === 0 && (
                <div>
                    <h2 className="text-lg font-semibold mb-2">Twins are born</h2>
                    <p>Dr Beech testifies that she gave Baby A breathing support immediately after he's born "to inflate the lungs, to get the required heart rate". Baby A improves and is able to breathe by himself.</p>
                    <p>The decision is made to transfer the twins to the neonatal ICU. There are 4 rooms, the actual ICU (nursery 1), 2 low intensity rooms (3 & 4), and one inbetween (nursery 2). The babies are transferred to the ICU.</p>
              </div>
              )}
              {selectedOption === 1 && (
                <div>
                    <p>Dr Andrew Brunton records Baby A is stable on CPAP but an X-ray has revealed Baby A has a symptom of Newborn Respiratory Distress Syndrome.</p>
                </div>
              )}
              {selectedOption === 2 && (
                <div>
                    <p>Lactate level recorded at 2.6. It is agreed this is out of bounds for a normal reading.</p>
                </div>
              )}
              {selectedOption === 3 && (
                <div>
                    <p>Nurse Melanie Taylor comes on shift.</p>
                </div>
              )}
              {selectedOption === 4 && (
                <div>
                    <p>Lactate level recorded at 2.7. It is agreed this is out of bounds for a normal reading.</p>
                    <p>It's also around this time Baby A's respiratory rate was recorded as elevated and blood gases records were "outside optimum range".</p>
                </div>
              )}

            {selectedOption === 6 && (
                <div>
                    <p>Dr Harkness inserts a longline into Baby A. An X-Ray is taken to review placement, Dr Harkness assess that the longline should be moved back slightly due to the proximity to Baby A's heart.</p>

                </div>
              )}
            {selectedOption === 7 && (
                <div>
                    <h2 className="text-lg font-semibold mb-2">Letby Arrives on shift</h2>
                    <p>She begins by doing various other handover preparation tasks outside of the nursery, official handover from Nurse Taylor is at 8pm</p>
                </div>
              )}

            {selectedOption === 8 && (
                <div>
                    <p>Letby is definitively placed in Nursery 1. Letby and Nurse Taylor begin handover by doing equipment checks.</p>

                </div>
              )}
            {selectedOption === 9 && (
                <div>
                    <p>Letby and Nurse Taylor cosign a prescription of 10% Dextrose infusion. Letby says it was Taylor who actually administered it. Taylor says she cannot recall who did.</p>
                    
                    <p>We know Nursery 1 is 8 by 5 meters and present are: Dr Harkness, Dr Wood, and Nurses Letby and Taylor. Baby A and Baby B are adjacent to each other, a third baby is also present and Dr Harkness is attending to this baby.</p>
                    <AHandover className="w-128" />
                </div>
              )}
            {selectedOption === 10 && (
                <div>
                    <p>The computer on the unit logs that Nurse Taylor has started her note as part of the handover.</p>
                    <p>Nurse Taylor testifies that from her vantage point at the computer she could see Baby A's incubator</p>
                    <AEvent className="w-128" />
                </div>
              )}

            {selectedOption === 11 && (
                <div>
                    <p>Letby notes Baby A's feet are pale. Taylor gets up to help Letby.</p>
                </div>
              )}
            {selectedOption === 12 && (
                <div>
                    <p>The whole room is now responding to the collapse of Baby A including Dr Harkness and Dr Wood. Dr Harkness immediately removes the long line he previously suspected was too close to Baby A's heart.</p>

                    <p>Baby A will now sadly die. However our timeline stops because at this point because by whichever means they accused her of poisoning the baby it must have already happened.</p>

                    <h3>Wait when?</h3>
                    <p>Well, it can't have been before <b>8:05pm</b> since Letby is either not in the room or is with Nurse Taylor. It can't be after <b>8:18pm</b> because Nurse Taylor is sitting feet away in full view of the incubator. This means in the 13 minutes between them, or 5-18 minutes after handing over, Nurse Taylor must have turned her back for a second and Lucy Letby decided to quickly open the Baby's incubator and inject him with air, just a few feet from two doctors and Nurse Taylor.</p>

                    <h4>If that makes sense to you then I have a bridge to sell you.</h4>
                    <p>The point of handover is to end Nurse Taylor's shift. She must have waited for Nurse Taylor to turn her back but then why not just wait a few minutes longer for Nurse Taylor to go home? Why do it when the room is at peak occupancy with 2 doctors and 2 nurses. The doctors will also be called away throughout the shift. Not only was she able to pull this off but she pulled it off without anyone else in the room suspecting a thing. By the way, this is her first alleged victim.</p>

                    <AEvent className="w-128" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LetterPanel;