// BarChart.js
import React from 'react';
import LetterPanel from './LetterPanel';

const FunnelChart = ({ data }) => {
    return (
        <LetterPanel />
    );
}

export default FunnelChart;