// BayesHelper.js
import React, { useState, useEffect } from 'react';
import FunnelChart from './Funnel';
import { Slider, TextField, Typography } from '@mui/material';
import KatexRenderer from './KatexRenderer';


const BayesHelper = ({initialFp}) => {
    const [drEvansFalsePosRate, setdrEvansFalsePosRate] = useState(initialFp);
    const [drEvanTruePosRate, setdrEvanTruePosRate] = useState(1.0);
    const [numMurders, setNumMurders] = useState(4);
    const [numDeaths, setNumDeaths] = useState(20000.0);
    const [actualMurders, setActualMurders] = useState(Math.floor(numMurders * drEvanTruePosRate));
    const [falseMurders, setFalseMurders] = useState(Math.floor((numDeaths - numMurders) * drEvansFalsePosRate));
  
    const bayesPos = "P(Murder | +) = \\frac{P(+ | Murder) * P(Murder)}{P(+)}"
    const bayesPos2 = "P(Murder | +) = \\frac{P(+ | Murder) * P(Murder)}{P(+ | Murder) * P(Murder) + P(+ | NonMurder) * P(NonMurder)}"
    const [bayesPos3, setBayesPos3] = useState("");
    const [bayesPos4, setBayesPos4] = useState("");



    const [dataNonMurders, setNonMurderData] = useState([
        {
          "id": "Deaths",
          "value": numDeaths,
          "label": "Deaths"
        },
        {
          "id": "Non-murders",
          "value": numDeaths - numMurders,
          "label": "Non-murders"
        },
        {
          "id": "Incorrectly identified",
          "value": (numDeaths - numMurders) * 0.0002,
          "label": "Incorrectly identified as murder"
        },
      ]);
    
      const [dataMurders, setMurderData] = useState([
        {
          "id": "Deaths",
          "value": numDeaths,
          "label": "Deaths"
        },
        {
          "id": "Murders",
          "value": numMurders,
          "label": "Murders"
        },
        {
          "id": "Caught",
          "value": numMurders,
          "label": "Caught"
        },
      ]);



    useEffect(() => {
        const priorMurder = parseFloat(parseFloat(numMurders) / numDeaths)
        const priorNotMurder = 1.0 - priorMurder
    
        const priorMurderStr = (priorMurder).toLocaleString('en-US', { maximumSignificantDigits: 3 });
        const priorNotMurderStr = priorNotMurder
    
        setBayesPos3("P(Murder | +) = \\frac{ " + drEvanTruePosRate + " * " + priorMurderStr + "}{ " + drEvanTruePosRate + " * " + priorMurderStr + "+ " + drEvansFalsePosRate + " * " + priorNotMurderStr + "}")
    
        const murderGivenP = (drEvanTruePosRate * priorMurder) / + (drEvanTruePosRate * priorMurder + drEvansFalsePosRate * priorNotMurder);
        const murderGivenPPer = (murderGivenP * 100).toLocaleString('en-US', { maximumSignificantDigits: 3 });
    
        setBayesPos4("P(Murder | +) = " + murderGivenP.toLocaleString('en-US', { maximumSignificantDigits: 3 }) + " = " + murderGivenPPer + "\\%")
    
        const actual = Math.floor(numMurders * drEvanTruePosRate);
        const fMurder = Math.floor((numDeaths - numMurders) * drEvansFalsePosRate);
    
        setFalseMurders(fMurder);
        setActualMurders(actual);
        setNonMurderData([
          {
            "id": "Deaths",
            "value": numDeaths,
            "label": "Deaths"
          },
          {
            "id": "Non-murders",
            "value": numDeaths - numMurders,
            "label": "Non-murders"
          },
          {
            "id": "Incorrectly identified",
            "value": fMurder,
            "label": "Incorrectly identified as murder"
          },
        ]);
        setMurderData([
          {
            "id": "Deaths",
            "value": numDeaths,
            "label": "Deaths"
          },
          {
            "id": "Murders",
            "value": numMurders,
            "label": "Murders"
          },
          {
            "id": "Caught",
            "value": actual,
            "label": "Caught"
          },
        ]);
      }, [drEvansFalsePosRate, drEvanTruePosRate, numMurders, numDeaths]);
    

    const handleDrEvansFalsePosRateChange = (event, newValue) => {
        setdrEvansFalsePosRate(newValue);
      };
    
      const handleDrEvansTruePosChange = (event, newValue) => {
        setdrEvanTruePosRate(newValue);
      };
    
      const handleNumMurdersChange = (event) => {
        const newVal = parseFloat(event.target.value);
        if (isNaN(newVal))
          return;
    
        if (parseFloat(newVal) > numDeaths)
          setNumMurders(numDeaths)
        else
          setNumMurders(newVal);
      };
    
      const handleNumDeathsChange = (event) => {
        const newVal = parseFloat(event.target.value);
        if (isNaN(newVal))
          return;
    
        setNumDeaths(newVal);
      };
    


    return (
        <div>
        <KatexRenderer math={bayesPos} displayMode={false} />
        <KatexRenderer math={bayesPos2} displayMode={false} />
        <KatexRenderer math={bayesPos3} displayMode={false} />
        <KatexRenderer math={bayesPos4} displayMode={false} />

        <div style={{ display: 'flex', justifyContent: 'space-around', padding: '20px' }}>
          <div style={{ width: '25%' }}>
            <Typography gutterBottom>False positive rate</Typography>
            <Slider
              value={drEvansFalsePosRate}
              onChange={handleDrEvansFalsePosRateChange}
              aria-labelledby="FalsePos"
              valueLabelDisplay="auto"
              step={0.01}
              min={0.01}
              max={1}
            />
          </div>
          <div style={{ width: '25%' }}>
            <Typography gutterBottom>True positive rate</Typography>
            <Slider
              value={drEvanTruePosRate}
              onChange={handleDrEvansTruePosChange}
              aria-labelledby="True pos"
              valueLabelDisplay="auto"
              step={0.01}
              min={0.01}
              max={1}
            />
          </div>
          <div style={{ width: '25%' }}>
            <Typography gutterBottom>Deaths</Typography>
            <TextField
              label="Num deaths (incl murders)"
              value={numDeaths}
              onChange={handleNumDeathsChange}
            />
          </div>
          <div style={{ width: '25%' }}>
            <Typography gutterBottom>Murders</Typography>
            <TextField
              label="Num murders"
              value={numMurders}
              onChange={handleNumMurdersChange}
            />
          </div>

        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', height: '500px' }}>
          <div style={{ width: '45%' }}>
            <h4>Actual murderers caught: {actualMurders}</h4>
            <FunnelChart data={dataMurders} />
          </div>
          <div style={{ width: '45%' }}>
            <h4>Non murders falsely identified: {falseMurders}</h4>
            <FunnelChart data={dataNonMurders} />
          </div>
        </div>
        </div>
    );
}

export default BayesHelper;