// BarChart.js
import React from 'react';
import { ResponsiveFunnel } from '@nivo/funnel'

const FunnelChart = ({ data }) => {
    return (
        <ResponsiveFunnel
        data={data}
        margin={{ top: 20, right: 20, bottom: 100, left: 20 }}
        shapeBlending={0.09}
        valueFormat=">-.4s"
        colors={{ scheme: 'spectral' }}
        borderWidth={1}
        labelColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    3
                ]
            ]
        }}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={10}
        currentBorderWidth={3}
        motionConfig="wobbly"
    />
    );
}

export default FunnelChart;