import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './D3Graph.css';
import Dropdown from './Dropdown';
import KatexRenderer from './KatexRenderer';

const D3Graph = () => {
    const rowHeight = 15;
    const colWidth = 15;
    const maxRows = 38;
    const maxCols = 730;

    const [globalNumEventsForSelected, setGlobalNumEventsForSelected] = useState(0);

    const [numRows, setNumRows] = useState(38);
    const [numCols, setNumCols] = useState(730);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    // 0 = rota, 1 = deathOnShift, 2 = rotaXDeathOnShift
    const [selectedView, setSelectedView] = useState(2);

    const svgRef = useRef(null);
    const newSvgRef = useRef(null);

    const [pEvent, setPEvent] = useState(0.11);
    const [pWorking, setPWorking] = useState(0.25);

    const colorSelectionBox = getComputedStyle(document.documentElement).getPropertyValue('--color-selection-box').trim();

    const equation1 = "P(w) * P(e)" 
    const [equation2, setEquation2] = useState("");
    const [equation3, setEquation3] = useState("");
    const equation4 = "P(w)^{SE} = 0.28 ^ {25} = " + (Math.pow(0.28, 25) * 100.0).toLocaleString('en-US', { maximumSignificantDigits: 3 }) + "\\%";
    const equation5 = "(1 - P(w)^{SE}) ^ {N - 1} = (1 - 0.28 ^ {25}) ^ {37} = " + Math.pow(1.0 - Math.pow(0.28, 25.0), 37.0) * 100.0 + "\\%";

    const d3Container = useRef(null);

    useEffect(() => {
        if (d3Container.current) {

            const start = 0.8
            const step = 0.01
            // Generate data
            const data = d3.range(start, 0.97, step).map(N => Math.pow(1 - Math.pow(N, 25), 37));
      
            // Set dimensions and margins
            const margin = { top: 20, right: 30, bottom: 40, left: 50 };
            const width = 1000 - margin.left - margin.right;
            const height = 300 - margin.top - margin.bottom;
      
            // Remove any existing SVG
            d3.select(d3Container.current).select('svg').remove();
      
            // Create SVG container
            const svg = d3.select(d3Container.current)
              .append('svg')
              .attr('width', width + margin.left + margin.right)
              .attr('height', height + margin.top + margin.bottom)
              .append('g')
              .attr('transform', `translate(${margin.left},${margin.top})`);
      
            // X scale and axis
            const xScale = d3.scaleBand()
              .domain(data.map((d, i) => i))
              .range([0, width])
              .padding(0.2);
      
            const xAxis = d3.axisBottom(xScale)
              .tickFormat((d, i) => (12 * (14 * (start + step*i))).toFixed(2));
      
            // Y scale and axis
            const yScale = d3.scaleLinear()
              .domain([0, d3.max(data)])
              .range([height, 0]);
      
            const yAxis = d3.axisLeft(yScale)
              .tickFormat(d => (d * 100).toFixed(2));
      
            // Add X axis
            svg.append('g')
              .attr('transform', `translate(0,${height})`)
              .call(xAxis)
              .append('text')
              .attr('x', width / 2)
              .attr('y', margin.bottom - 10)
              .attr('fill', 'black')
              .style('text-anchor', 'middle')
              .text('hours need to work per week');
      
            // Add Y axis
            svg.append('g')
              .call(yAxis)
              .append('text')
              .attr('transform', 'rotate(-90)')
              .attr('x', -height / 2)
              .attr('y', -margin.left + 10)
              .attr('fill', 'black')
              .style('text-anchor', 'middle')
              .text('% chance');
      
            // Create bars
            svg.selectAll('.bar')
              .data(data)
              .enter()
              .append('rect')
              .attr('class', 'bar')
              .attr('x', (d, i) => xScale(i))
              .attr('y', d => yScale(d))
              .attr('width', xScale.bandwidth())
              .attr('height', d => height - yScale(d))
              .attr('fill', 'steelblue');
          }
    }, [d3Container.current]);
      
    useEffect(()=>{
        // Update equations.
        setEquation2("P(w) ^ {SE} = " + pWorking + "^{" + globalNumEventsForSelected + "} = {" + (Math.pow(pWorking, globalNumEventsForSelected)* 100.0).toLocaleString('en-US', { maximumSignificantDigits: 3 }) + "\\%}")
        setEquation3("(1 - P(w)^{SE}) ^ {N - 1} = (1 - " + Math.pow(pWorking, globalNumEventsForSelected).toLocaleString('en-US', { maximumSignificantDigits: 3 }) + ") ^ {" + (numRows -1) + "} = " + Math.pow(1.0 - Math.pow(pWorking, globalNumEventsForSelected), numRows - 1.0) * 100.0 +"\\%");
    }, [pWorking, globalNumEventsForSelected, numRows]);

      
    function generateRota() {
        let workschedule = Array.from({ length: maxRows }, () =>
            Array.from({ length: maxCols }, () => false)
        );
        for (let i = 0; i < maxCols; i++) {
            for (let j = 0; j < maxRows; j++) {
                var baseProb = pWorking;

                workschedule[j][i] = Math.random() < baseProb;
            }
        }

        return workschedule;
    }

    function generateDeathOnShiftData() {
        var data = Array.from({ length: maxRows }, (j) =>
            Array.from({ length: maxCols }, (i) => false)
        );
        for (let i = 0; i < maxCols; i++) {
            const deathOccurred = Math.random() < pEvent;
            for (let j = 0; j < maxRows; j++) {
                data[j][i] = deathOccurred;
            }
        }
        return data;
    }

    function getRotaXDeathOnShift() {
        var data = Array.from({ length: maxRows }, (j) =>
            Array.from({ length: maxCols }, (i) => false)
        );
        for (let j = 0; j < maxRows; j++) {
            for (let i = 0; i < maxCols; i++) {
                data[j][i] = rota[j][i] && eventData[j][i];
            }
        }
        return data;
    }

    const [rota, setRota] = useState(generateRota());
    const [eventData, setEventData] = useState(generateDeathOnShiftData());
    const [rotaXEvent, setRotaXEvent] = useState(getRotaXDeathOnShift());

    useEffect(() => {
        setRota(generateRota());
    }, [pWorking]);

    useEffect(() => {
        setEventData(generateDeathOnShiftData());
    }, [pEvent]);

    useEffect(() => {
        setRotaXEvent(getRotaXDeathOnShift());
    }, [rota, eventData]);


    useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove(); // Clear previous content

        var sliced;
        var trueCol = "red", falseCol = "white";

        if (selectedView === 0) {
            sliced = rota.slice(0, numRows).map(row => row.slice(0, numCols));
            trueCol = "blue";
            falseCol= "white";
        } else if (selectedView === 1) {
            sliced = eventData.slice(0, numRows).map(row => row.slice(0, numCols));
            trueCol = "red";
            falseCol= "white";
        } else {
            sliced = rotaXEvent.slice(0, numRows).map(row => row.slice(0, numCols));
            trueCol = "red";
            falseCol= "white";
        }
        renderTable(svg, sliced, falseCol, trueCol);


        if (selectedRowIndex !== null) {
            const newSvg = d3.select(newSvgRef.current);
            newSvg.selectAll('*').remove(); // Clear previous content

            sliced = rotaXEvent.slice(0, numRows).map(row => row.slice(0, numCols));
            trueCol = "red";
            falseCol= "white";

            renderNewTable(newSvg, sliced);
        }
    }, [numRows, numCols, rota, eventData, rotaXEvent, selectedRowIndex, selectedView]);

    const renderTable = (svg, data, falseCol, trueCol) => {
        svg
            .attr('width', colWidth * numCols)
            .attr('height', rowHeight * numRows);

        const rows = svg
            .selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(0, ${(i * rowHeight)})`);

        rows
            .selectAll('rect')
            .data((d, i) => d.map(cell => ({ cell, rowIndex: i })))
            .enter()
            .append('rect')
            .attr('width', colWidth)
            .attr('height', rowHeight)
            .attr('x', (d, i) => i * colWidth)
            .attr('class', function (d) {
                return d.cell ? trueCol : falseCol;
            })
            .on('click', function (event, d) {
                setSelectedRowIndex(d.rowIndex);
            });


        // Add a highlight rectangle for the selected row
        if (selectedRowIndex !== null) {
            svg
                .append('rect')
                .attr('x', 0)
                .attr('y', selectedRowIndex * rowHeight)
                .attr('width', colWidth * numCols)
                .attr('height', rowHeight)
                .attr('fill', 'none')
                .attr('stroke', colorSelectionBox)
                .attr('stroke-width', 2);
        }
    };

    const renderNewTable = (svg, currentData) => {
        var numEventsForSelected = 0
        for (let i = 0; i < numCols; i++) {
            numEventsForSelected += currentData[selectedRowIndex][i];
        }

        setGlobalNumEventsForSelected(numEventsForSelected);
    
        let data = Array.from({ length: numRows }, () => 
            Array.from({ length: numEventsForSelected }, () => false)
        );
    
        for (let j = 0; j < numRows; j++) {
            var tail = 0;
            for (let i = 0; i < numCols; i++) {
                if (currentData[selectedRowIndex][i]) {
                    data[j][tail] = currentData[j][i];
                    tail += 1;
                }
            }
        }
        
        const reorderedData = [data[selectedRowIndex], ...data.slice(0, selectedRowIndex), ...data.slice(selectedRowIndex + 1)];    
        const offset = 10; 
        const labelWidth = 125;
        svg
            .attr('width', colWidth * numEventsForSelected + labelWidth)
            .attr('height', rowHeight * numRows + offset);

        const rows = svg
            .selectAll('g')
            .data(reorderedData)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(0, ${i * rowHeight + offset})`);
      
        rows
            .append('text')
            .attr('x', 0)
            .attr('y', rowHeight)
            .attr('dx', '0.5em')
            .text((_, i) => i === 0 ? "Suspect " + (selectedRowIndex + 1) : "Suspect " + (((i-1) < selectedRowIndex) ? i : i + 1))
            .attr('class', 'row-label');
      
        rows
            .selectAll('rect')
            .data((d, i) => d.map(cell => ({ cell, rowIndex: i })))
            .enter()
            .append('rect')
            .attr('width', colWidth)
            .attr('height', rowHeight)
            .attr('x', (d, i) => labelWidth + i * colWidth)
            .attr('class', d => (d.cell ? 'red' : 'white'));

        svg
            .append('rect')
            .attr('x', labelWidth)
            .attr('y', offset)
            .attr('width', colWidth * numEventsForSelected)
            .attr('height', rowHeight)
            .attr('fill', 'none')
            .attr('stroke', colorSelectionBox)
            .attr('stroke-width', 2);

    };

    const handleNumRowsChange = (event) => {
        setNumRows(event.target.value);
    };

    const handleNumColsChange = (event) => {
        setNumCols(event.target.value);
    };

    const handlePEventChange = (event) => {
        setPEvent(Number(event.target.value));
    };

    const handlePWorkingChange = (event) => {
        setPWorking(Number(event.target.value));
    };

    const handleRegenerateData = () => {
        setRota(generateRota());
        setEventData(generateDeathOnShiftData());
    };

    const handleViewShiftRota = () => {
        setSelectedView(0);
    };

    const handleViewEvents = () => {
        setSelectedView(1);
    };

    const handleViewXRota = () => {
        setSelectedView(2);
    };

    return (
        <div>
            <div className="description">
                <p>This interactive table shows a randomly generated set of hospital data. Each row is a suspect and each column a shift. At some random chance P(e) a shift will be marked as having an event. Independently each suspect has a P(w) chance of working. It defaults to 38 suspects and 730 shifts to represent one year (12 hour shifts). The data is generated using: probability of Event P(e)=<span className="event-probability">{pEvent * 100}%</span> and probability any suspect working a shift P(W)=<span className="working-probability">{pWorking * 100}%</span>. You can scroll left or right to view more of the data and can click or tap on a row to isolate them as a suspect. Try and find the "single common factor".</p>
            </div>
            <button onClick={handleRegenerateData}>Regenerate Data</button>
            <button onClick={handleViewShiftRota}>View Shift Rota</button>
            <button onClick={handleViewEvents}>View Events</button>
            <button onClick={handleViewXRota}>View Rota X Events</button>

            {selectedView === 0 && (
                <p>Each blue box represents a shift worked by a suspect</p>
            )}
            {selectedView === 1 && (
                <p>Each red column shows a shift where an event occurred. Each row is one suspect, in this view the box is red regardless of them working or not.</p>
            )}
            {selectedView === 2 && (
                <p>This view shows shifts where events occurred AND the person in that row was working that shift.</p>
            )}
            <div className="table-container">
                <svg ref={svgRef}></svg>
            </div>
            <Dropdown>
                <div className="controls">
                    <label>
                        Number of Rows:
                        <input type="range" min="1" max={maxRows} value={numRows} onChange={handleNumRowsChange} />
                        {numRows}
                    </label>
                    <label>
                        Number of Columns:
                        <input type="range" min="1" max={maxCols} value={numCols} onChange={handleNumColsChange} />
                        {numCols}
                    </label>
                    <label>
                        Probability of Event:
                        <input type="range" min="0" max="1" step="0.01" value={pEvent} onChange={handlePEventChange} />
                        {pEvent}
                    </label>
                    <label>
                        Probability of Working a Shift:
                        <input type="range" min="0" max="1" step="0.01" value={pWorking} onChange={handlePWorkingChange} />
                        {pWorking}
                    </label>
                </div>
            </Dropdown>
            {selectedRowIndex === null && (
            <div>
            <h1>
                Click or tap a row.
            </h1>
            </div>
            )}
            {selectedRowIndex !== null && (
            <p>
                Congratulations! You likely found the "single common factor". This table has your suspect at the top with all shifts they worked with no event filtered out. Click again to see another nurse.
            </p>
            )}
            {selectedRowIndex !== null && (
                <div className="details-table-container">
                    <svg ref={newSvgRef}></svg>
                </div>
            )}
            {selectedRowIndex !== null && (
                <div className="description">
                <p>For any other person the chance of appearing in this table is <b>unconnected to the probability of the event</b> and so is just the base probability of them working. Consider, as the probability of event and working are random and independent, the probability of any person working when an event happens (in the main table) is simply:</p> 
                <KatexRenderer math={equation1} displayMode={false} />
                <p>
                    Once you have selected an individual and remove the shifts where an event DOESN'T occur the P(E) is 100% in that subset.
                    Assuming there is some way to "pass" this test it would presumably be to show there is more than one "common factor", i.e on all the shifts someone else also was there.
                    Since for this suspect we found <span className="event-probability">{globalNumEventsForSelected}</span> shifts with events (SE) with this suspect on shift that means the probability of any other person working the same pattern as our suspect is:
                </p>
                <KatexRenderer math={equation2} displayMode={false} />
                <p>So the chance of two or more lines <b>NOT</b> appearing in the above table is (or the chance of seeing a <i>single</i> common factor): </p>
                <KatexRenderer math={equation3} displayMode={false} />

                <p>You can change the parameters in the above table to see how it affects the probability. With the default 38 suspects the chance of someone having the same shifts as another in this subset is ~0% until you hit 90% chance of working. That would correspond to that person working <b>144-156 hours a week, for a year</b> (assuming 12 hour shifts). It's actually still unlikely at that point. They would need to be working over 94% of the time before the chance of seeing 2 lines rises above 50%.</p>
                <p><b>In short, you're not going to see 2 lines. You will always find the "single common factor" even though this is random data.</b></p>
                <p>For all sensible working hours you're guaranteed to find the same pattern in random data structured in the same way. You will always see just one "common factor". It just shows that this table is meaningless without analysis to show that the pattern is statistically distinguishable from noise.</p>
                <p>This section was inspired in part by the prior work done <a href='https://www.scienceontrial.com/post/shifting-the-data'>by others who showed how easy it is to recreate the shift pattern from noise.</a></p>
                </div>
            )}

        </div>
    );
};

export default D3Graph;
