import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import Test from './Test';
import D3Graph from './D3Graph';
import BayesHelper from './BayesHelper';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/appeal" element={ 
          <meta http-equiv="refresh" content="0; url=https://triedbystats-public-site-resources.s3.us-west-2.amazonaws.com/20240702-R-v-Letby-conviction-application.pdf" />
          } />
        <Route path="/test" element={<Test />} />
        <Route path="/shifts" element={<D3Graph />} />
        <Route path="/bayes" element={ 
          <div>
          <h1>There's been a murder?</h1>
          <p>Murder is an extremely uncommon cause of death. If you have a list of deaths, say in a neonatal unit, any death selected has a very low chance of being caused by murder. As we introduce each piece of evidence that probability increases or decreases. So, now a medical investigator comes along and says it is a murder. However, we know they have been wrong before. They may identify a death as murder, when it isn't (false positive) and when given a murder they have only a certain chance of correctly identifying it (true positive). So if we pick a death, give it to them to investigate, and they say it is murder, what is the chance it is actually murder?</p>
          <p>To answer that you use bayes rule to find the new probability of murder given the positive result, written as P(Murder|+).</p>
          <BayesHelper initialFp={0.07}/>
          <p>On the initial values: The initial 4 murders are the Beverly Allitt murders, and the 20000 other deaths are a rough estimate for the number of baby deaths in hospital over the 25 years between 1991-2016. Initially we assume he's never wrong when it is murder and has a false positive rate of 7%.</p>
          <p>You may think, well this is silly how could the probability decrease? It's hasn't. That number is still higher than the PRIOR probability when we came in. After we heard the medical investigator it became more likely but it hasn't switched to 100%. If the medical signal is so uncontroversially murder there's no issue because any other impartial investigator could look at it and increase the probability. For instance two <i>independent</i> medical investigators each with a 7% false positive rate both concluding murder would result in a posterior probability of 3.86%. A third would bring it to 36.1%. However it also goes in the other direction. Suppose an investigator is looking at a case and he relies on an obscure medical paper to conclude a rare cause of death he's never seen before since he can't figure out any other reason. What happens to the probability when one of the authors of that paper who is a genuine expert in that field says:</p> 
          <blockquote>An author of the paper, Shoo Lee, one of the most prominent neonatologists in Canada, has since reviewed summaries of each pattern of skin discoloration in the Letby case and said that none of the rashes were characteristic of air embolism. He also said that air embolism should never be a diagnosis that a doctor lands on just because other causes of sudden collapse have been ruled out: “That would be very wrong—that's a fundamental mistake of medicine.”
          <footer><cite><a href='https://web.archive.org/web/20240515101321/https://www.newyorker.com/magazine/2024/05/20/lucy-letby-was-found-guilty-of-killing-seven-babies-did-she-do-it'>New Yorker Article (before a British court made them remove it)</a></cite></footer></blockquote>
          <p>What do you think that does to the probability? As always forward all questions, comments, corrections and court orders to triedbystats@gmail.com.</p>
          </div>
        } />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
