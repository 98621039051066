import React from 'react';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import './D3Graph.css';

const KatexRenderer = ({ math, displayMode }) => {
  return (
    <div style={{ overflowX: 'auto', textAlign: 'center', whiteSpace: 'nowrap' }}>
      <BlockMath math={math} />
    </div>
  );
};

export default KatexRenderer;