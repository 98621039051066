// BarChart.js
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const BarChart = ({ data, keys }) => {
    return (
        <ResponsiveBar
            data={data}
            keys={keys}
            indexBy="id"
            margin={{ top: 50, right: 0, bottom: 50, left: 10 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Deaths',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
        />
    );
}

export default BarChart;